import { useState } from "react";
import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { database } from "../firebaseInit";

const PostNote = ({ onError }) => {
	const [text, setText] = useState();
	const history = useHistory();

	const onFormSubmit = (e) => {
		e.preventDefault();
		if (text == null || text === "") { return; }
		const newPostKey = database.ref().child("posts").push().key;
		let updates = {};
		updates["/posts/" + newPostKey] = {
			body: text,
			timestamp: moment().unix()
		};
		database.ref().update(updates).then((error) => {
			if (error) { return onError(error); }
			history.push("/notes");
		});
	};

	return (
		<Form onSubmit={onFormSubmit}>
			<Form.Group>
				<Row>
					<Col>
						<h1>New Note</h1>
						<Form.Control
							value={text}
							as="textarea"
							rows={3}
							onChange={(e) => setText(e.target.value)}/>
					</Col>
				</Row>
				<Row className="form-row justify-content-end">
					<Col xs="auto" className="justify-content-end">
						<Button variant="primary" type="submit">Submit</Button>
					</Col>
				</Row>
			</Form.Group>
		</Form>
	);
};

export default PostNote;
