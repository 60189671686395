import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { auth } from "../firebaseInit";
import LockLogoLight from "./unlock-alt-solid-white.svg";
import LockLogoDark from "./unlock-alt-solid.svg";
import SignOutLogoLight from "./sign-out-alt-solid-white.svg";
import SignOutLogoDark from "./sign-out-alt-solid.svg";

const Header = ({ logout }) => {
	const [user, setUser] = useState(null);
	const history = useHistory();
	const showTitle = window.innerWidth > 600;
	const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
	const modeString = darkMode ? "dark" : "light";

	const logoutHandler = () => {
		auth.signOut();
		history.push("/login");
	};

	auth.onAuthStateChanged((newUser) => setUser(newUser));

				// <Nav.Link onClick={logoutHandler}>Logout</Nav.Link>

	// if logged in, return "post", "logout", if logged out, return "login"
	const renderAuthNav = () => (user != null
		? <>
				<Nav.Link as={Link} to="/dose">Dose</Nav.Link>
				<Nav.Link onClick={logoutHandler}>
					<img
						alt="logout"
						src={darkMode ? SignOutLogoLight : SignOutLogoDark}
						style={{opacity: 0.6, width: "1rem", height: "1rem"}}>
					</img>
				</Nav.Link>
			</>
		: <Nav.Link className="justify-content-end" as={Link} to="/login">
				<img
					alt="login"
					src={darkMode ? LockLogoLight : LockLogoDark}
					style={{opacity: 0.6, width: "1rem", height: "1rem"}}>
				</img>
			</Nav.Link>
		);

	return (
		<Navbar bg={modeString} variant={modeString}>
			<Container>
				{showTitle ? <Navbar.Brand>Trans MtF Journal</Navbar.Brand> : null}
				<Nav>
					<Nav.Link as={Link} to="/hormones">Hormones</Nav.Link>
					<Nav.Link as={Link} to="/sizes">Sizes</Nav.Link>
					<Nav.Link as={Link} to="/notes">Notes</Nav.Link>
					{renderAuthNav()}
				</Nav>
			</Container>
		</Navbar>
	);
};

export default Header;
