export const estrogenInjectionDaily = () => {
	const days = Array.from(Array(42))
		.map(() => ({ e1: 0, e2: 0 }));
	// const e2 = [
	// 	0, 25, 66, 108, 135, 151, 158,
	// 	158, 152, 143, 134, 121, 108, 95,
	// 	87, 75, 67, 57, 50, 44, 38,
	// 	32, 27, 24, 20, 17, 14, 11,
	// 	10, 9, 8, 7, 6, 5, 4,
	// 	4, 3, 3, 2, 2, 1, 1
	// ];
	const e2 = [0, 5, 13.2, 21.6, 27, 30.2, 31.6, 31.6, 30.4, 28.6, 26.8, 24.2, 21.6, 19, 17.4, 15, 13.4, 11.4, 10, 8.8, 7.6, 6.4, 5.4, 4.8, 4, 3.4, 2.8, 2.2, 2, 1.8, 1.6, 1.4, 1.2, 1, 0.8, 0.8, 0.6, 0.6, 0.4, 0.4, 0.2, 0.2];
	for (let i = 0; i < days.length - 1; i++) {
		days[i].e1 = e2[i] * 0.4;
		days[i].e2 = e2[i];
	}
	return days;
};

export const estrogenInjectionHourly = () => {
	const hours = Array.from(Array(24 * 42))
		.map(() => ({ e1: 0, e2: 0 }));
	const days = estrogenInjectionDaily();
	days.forEach((day, i, arr) => {
		const nextDay = arr[(i + 1)] || ({ e1: 0, e2: 0 });
		Array.from(Array(24)).forEach((_, j) => {
			const t = j / 24;
			hours[i * 24 + j].e1 = day.e1 * (1 - t) + nextDay.e1 * (t);
			hours[i * 24 + j].e2 = day.e2 * (1 - t) + nextDay.e2 * (t);
		});
	});
	return hours;
};

export const estrogenInjectionQuarterHourly = () => {
	const hours = Array.from(Array(24 * 42 * 4))
		.map(() => ({ e1: 0, e2: 0 }));
	const days = estrogenInjectionDaily();
	days.forEach((day, i, arr) => {
		const nextDay = arr[(i + 1)] || ({ e1: 0, e2: 0 });
		Array.from(Array(24 * 4)).forEach((_, j) => {
			const t = j / (24 * 4);
			hours[i * (24 * 4) + j].e1 = day.e1 * (1 - t) + nextDay.e1 * (t);
			hours[i * (24 * 4) + j].e2 = day.e2 * (1 - t) + nextDay.e2 * (t);
		});
	});
	return hours;
};

// given one dose of estradiol (1mg)
// this is the result to each hormone, one entry per hour
export const estrogenSwallowHourly = () => {
	const hours = Array.from(Array(96))
		.map(() => ({ e1: 0, e2: 0 }));
	const e1_delivery = [0, 60, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10, 5];
	const e1_elimination = 0.9;
	const e2_delivery = e1_delivery.map(n => n * 0.15);
	const e2_elimination = 0.9;
	for (let i = 0; i < hours.length - 1; i++) {
		hours[i].e1 += e1_delivery[i] ? e1_delivery[i] : 0;
		hours[i + 1].e1 = hours[i].e1 * e1_elimination;
		hours[i].e2 += e2_delivery[i] ? e2_delivery[i] : 0;
		hours[i + 1].e2 = hours[i].e2 * e2_elimination;
	}
	return hours;
};

export const estrogenSublingualHourly = () => {
	const hours = Array.from(Array(96))
		.map(() => ({ e1: 0, e2: 0, e2_a: 0, e2_b: 0 }));
	const e1_delivery = [0, 60, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10, 5];
	const e1_elimination = 0.9;
	const e2_delivery_a = [0, 400];
	const e2_elimination_a = 0.55;
	const e2_delivery_b = [0, 60];
	const e2_elimination_b = 0.96;
	for (let i = 0; i < hours.length - 1; i++) {
		hours[i].e1 += e1_delivery[i] ? e1_delivery[i] : 0;
		hours[i + 1].e1 = hours[i].e1 * e1_elimination;
		hours[i].e2_a += e2_delivery_a[i] ? e2_delivery_a[i] : 0;			
		hours[i + 1].e2_a = hours[i].e2_a * e2_elimination_a;
		hours[i].e2_b += e2_delivery_b[i] ? e2_delivery_b[i] : 0; 
		hours[i + 1].e2_b = hours[i].e2_b * e2_elimination_b;
	}
	for (let i = 0; i < hours.length; i++) {
		hours[i].e2 = hours[i].e2_a + hours[i].e2_b;
	}
	return hours;
};

// e1_delivery.map((n, i) => {
// 	let nx = i === e1_delivery.length-1 ? 0 : e1_delivery[i+1];
//   return [(n), (n*0.75+nx*0.25), (n*0.5+nx*0.5), (n*0.25+nx*0.75)]
// }).reduce((a, b) => a.concat(b)).map(n => n / 4)

export const estrogenSwallowQuarterHourly = () => {
	const hours = Array.from(Array(384))
		.map(() => ({ e1: 0, e2: 0 }));
	const e1_delivery = [0, 3.75, 7.5, 11.25, 15, 14.6875, 14.375, 14.0625, 13.75, 13.4375, 13.125, 12.8125, 12.5, 12.1875, 11.875, 11.5625, 11.25, 10.9375, 10.625, 10.3125, 10, 9.6875, 9.375, 9.0625, 8.75, 8.4375, 8.125, 7.8125, 7.5, 7.1875, 6.875, 6.5625, 6.25, 5.9375, 5.625, 5.3125, 5, 4.6875, 4.375, 4.0625, 3.75, 3.4375, 3.125, 2.8125, 2.5, 2.1875, 1.875, 1.5625, 1.25, 0.9375, 0.625, 0.3125];
	const e1_elimination = 0.975;
	const e2_delivery = e1_delivery.map(n => n * 0.15);
	const e2_elimination = 0.975;
	for (let i = 0; i < hours.length - 1; i++) {
		hours[i].e1 += e1_delivery[i] ? e1_delivery[i] : 0;
		hours[i + 1].e1 = hours[i].e1 * e1_elimination;
		hours[i].e2 += e2_delivery[i] ? e2_delivery[i] : 0;
		hours[i + 1].e2 = hours[i].e2 * e2_elimination;
	}
	return hours;
};

export const estrogenSublingualQuarterHourly = () => {
	const hours = Array.from(Array(384))
		.map(() => ({ e1: 0, e2: 0, e2_a: 0, e2_b: 0 }));
	const e1_delivery = [0, 3.75, 7.5, 11.25, 15, 14.6875, 14.375, 14.0625, 13.75, 13.4375, 13.125, 12.8125, 12.5, 12.1875, 11.875, 11.5625, 11.25, 10.9375, 10.625, 10.3125, 10, 9.6875, 9.375, 9.0625, 8.75, 8.4375, 8.125, 7.8125, 7.5, 7.1875, 6.875, 6.5625, 6.25, 5.9375, 5.625, 5.3125, 5, 4.6875, 4.375, 4.0625, 3.75, 3.4375, 3.125, 2.8125, 2.5, 2.1875, 1.875, 1.5625, 1.25, 0.9375, 0.625, 0.3125];
	const e1_elimination = 0.975;
	// const e2_delivery_a = [0, 100, 145, 155, 100, 30, 10]
	const e2_delivery_a = [0, 150, 190, 160, 100, 60, 20].map(n => n*0.85);
	const e2_elimination_a = 0.85;
	const e2_delivery_b = [0, 15, 11.25, 7.5, 3.75].map(n => n*1.7);
	const e2_elimination_b = 0.99;
	for (let i = 0; i < hours.length - 1; i++) {
		hours[i].e1 += e1_delivery[i] ? e1_delivery[i] : 0;
		hours[i + 1].e1 = hours[i].e1 * e1_elimination;
		hours[i].e2_a += e2_delivery_a[i] ? e2_delivery_a[i] : 0;			
		hours[i + 1].e2_a = hours[i].e2_a * e2_elimination_a;
		hours[i].e2_b += e2_delivery_b[i] ? e2_delivery_b[i] : 0; 
		hours[i + 1].e2_b = hours[i].e2_b * e2_elimination_b;
	}
	for (let i = 0; i < hours.length; i++) {
		hours[i].e2 = hours[i].e2_a + hours[i].e2_b;
	}
	return hours;
};

// old data, manually inputted
const hormones1mg = {
	e1: {
		swallow: [0, 90, 125, 150, 180, 190, 200, 210, 215, 215, 210, 205, 195, 185, 175, 160, 145, 135, 120, 105, 95, 80, 70, 60, 50],
		sublingual: [0, 90, 125, 150, 180, 190, 200, 210, 215, 215, 210, 205, 195, 185, 175, 160, 145, 135, 120, 105, 95, 80, 70, 60, 50],
		buccal: [0, 90, 125, 150, 180, 190, 200, 210, 215, 215, 210, 205, 195, 185, 175, 160, 145, 135, 120, 105, 95, 80, 70, 60, 50]
	},
	e2: {
		swallow: [0,15,20.83,25,30,31.66,33.3,35,35.83,35.83,35,34.16,32.5,30.8,29.166,26.66,24.16,22.5,20,17.5,15.8,13.3,11.6,10,8.3],
		sublingual: [0, 450, 200, 120, 83, 69, 52, 48, 45, 43, 42, 40, 38, 37, 35, 34, 32, 30, 29, 28, 27, 26, 26, 25, 25],
		buccal: [0, 450, 200, 120, 83, 69, 52, 48, 45, 43, 42, 40, 38, 37, 35, 34, 32, 30, 29, 28, 27, 26, 26, 25, 25]
	}
};

export const oldEstrogenSwallowHourly = () => {
	return Array.from(Array(hormones1mg.e1.swallow.length))
		.map((_, i) => ({
			e1: hormones1mg.e1.swallow[i],
			e2: hormones1mg.e2.swallow[i],
		}));
};

export const oldEstrogenSublingualHourly = () => {
	return Array.from(Array(hormones1mg.e1.swallow.length))
		.map((_, i) => ({
			e1: hormones1mg.e1.sublingual[i],
			e2: hormones1mg.e2.sublingual[i],
		}));
};
