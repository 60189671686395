import { useState } from "react";
import {
	ResponsiveContainer,
	BarChart,
	Bar,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip
} from "recharts";
import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import { pink, blue } from "../colors";

const ChartDoses = ({ doses, dailyDoses = [] }) => {

	const [dosesXDomain, setDomain] = useState([0, 1]);
	const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

	const filteredDoses = () => {
		if (!doses.estrogen) { return []; }
		return dailyDoses
			.slice(...dosesXDomain.map(n => n * dailyDoses.length));
	}

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className="custom-tooltip">
					<h5 className="label">{label}</h5>
					<ul>
						{payload.map((el, i) => (<li
							key={i}
							style={{color: el.color}}
							>{`${el.name}: ${el.value.toFixed(1)} mg`}</li>))}
					</ul>
				</div>
			);
		}
		return null;
	};

	const makeBarChart = (data, className) => {
		return (
		<ResponsiveContainer width="100%" height={300}>
			<BarChart
				className={className}
				height={300}
				data={data}>
				<Bar yAxisId="left" dataKey="estradiol" fill={pink} />
				<Bar yAxisId="right" dataKey="bicalutamide" fill={blue} />
					<CartesianGrid stroke={darkMode ? "#fff2" : "#0002"} strokeDasharray="5 5" />
					<XAxis
						stroke={darkMode ? "#aaa" : "black"}
						minTickGap={150}
						interval="preserveStartEnd"
						dataKey="datestring" />
					<YAxis
						stroke={darkMode ? "#aaa" : "black"}
						yAxisId="left"
						unit="mg"/>
					<YAxis
						stroke={darkMode ? "#aaa" : "black"}
						yAxisId="right"
						unit="mg"
						orientation="right"/>
				<Tooltip content={<CustomTooltip/>} />
			</BarChart>
		</ResponsiveContainer>)
	};

	return (<>
		{makeBarChart(filteredDoses(), "chart-doses")}
		<div className="date-select">
			<Range
				onChange={(...args) => setDomain(...args)}
				min={0}
				max={1}
				step={0.001}
				defaultValue={[0, 1]}
				style={{width: "400px"}}/>
		</div>
	</>);
}

export default ChartDoses;
