import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TextNote from "./TextNote";
import VideoNote from "./VideoNote";

const Notes = ({ posts }) => {
	// on page load or new posts, scroll to top
	useEffect(() => window.scrollTo(0, 0), [posts]);

	// there is no "post.type" in the database, depending on
	// the post's contents, infer the type and render it as such.
	const postNode = (post, i) => {
		if (post.body) { return (<TextNote key={i} post={post} />); }
		if (post.youtube) { return (<VideoNote key={i} post={post} />); }
		return (<div></div>);
	};

	const renderPosts = () => {
		if (!posts) { return null; }
		return posts
			.sort((a,b) => b.timestamp - a.timestamp)
			.map(postNode);
	}

	return (
		<Container>
			<Row>
				<Col className="m-auto" lg={10} xl={8}>
					{renderPosts()}
				</Col>
			</Row>
		</Container>
	);
};

export default Notes;