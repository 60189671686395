import moment from "moment";
import {
	getStartAndEndTime,
	ConstructQuarterHourBuckets,
	ConstructHourBuckets,
} from "./Time";
import {
	estrogenSwallowHourly,
	estrogenSublingualHourly,
	estrogenInjectionHourly,
	estrogenSwallowQuarterHourly,
	estrogenSublingualQuarterHourly,
	estrogenInjectionQuarterHourly,
} from "./DoseLevels";

const hourly1mg = {
	e1: { swallow: [], sublingual: [], buccal: [], injection: [] },
	e2: { swallow: [], sublingual: [], buccal: [], injection: [] }
};
estrogenSwallowHourly().forEach((el, i) => {
	hourly1mg.e1.swallow[i] = el.e1;
	hourly1mg.e2.swallow[i] = el.e2;
});
estrogenSublingualHourly().forEach((el, i) => {
	hourly1mg.e1.sublingual[i] = el.e1;
	hourly1mg.e2.sublingual[i] = el.e2;
	hourly1mg.e1.buccal[i] = el.e1;
	hourly1mg.e2.buccal[i] = el.e2;
});
estrogenInjectionHourly().forEach((el, i) => {
	hourly1mg.e1.injection[i] = el.e1;
	hourly1mg.e2.injection[i] = el.e2;
});

const quarterHourly1mg = {
	e1: { swallow: [], sublingual: [], buccal: [], injection: [] },
	e2: { swallow: [], sublingual: [], buccal: [], injection: [] }
};
estrogenSwallowQuarterHourly().forEach((el, i) => {
	quarterHourly1mg.e1.swallow[i] = el.e1;
	quarterHourly1mg.e2.swallow[i] = el.e2;
});
estrogenSublingualQuarterHourly().forEach((el, i) => {
	quarterHourly1mg.e1.sublingual[i] = el.e1;
	quarterHourly1mg.e2.sublingual[i] = el.e2;
	quarterHourly1mg.e1.buccal[i] = el.e1;
	quarterHourly1mg.e2.buccal[i] = el.e2;
});
estrogenInjectionQuarterHourly().forEach((el, i) => {
	quarterHourly1mg.e1.injection[i] = el.e1;
	quarterHourly1mg.e2.injection[i] = el.e2;
});

const ApplyHourlyDose = (h, hours, entry) => ["e1", "e2"].forEach((hormone) => {
	let hourDose = hourly1mg[hormone][entry.route];
	if (!hourDose) { return; }
	for (let i = 0; i < hourDose.length; i++) {
		if (hours[h+i] != null) {
			const magnitude = entry.mg * (entry.form === "valerate" ? 0.75 : 1.0);
			hours[h+i][hormone] += hourDose[i] * magnitude;
		}
	}
});

const ApplyQuarterHourlyDose = (h, quarters, entry) => ["e1", "e2"].forEach((hormone) => {
	let quarterHourDose = quarterHourly1mg[hormone][entry.route];
	if (!quarterHourDose) { return; }
	for (let i = 0; i < quarterHourDose.length; i++) {
		if (quarters[h+i] != null) {
			const magnitude = entry.mg * (entry.form === "valerate" ? 0.75 : 1.0);
			quarters[h+i][hormone] += quarterHourDose[i] * magnitude;
		}
	}
});

export const SimulateHourly = (estrogen, antiandrogen) => {
	const { start } = getStartAndEndTime(estrogen, antiandrogen);
	if (!start) { return []; }
	start.set({ minute: 0, second: 0 });
	const end = moment().set({ minute: 0, second: 0 }).add(3, "hour");
	const hours = ConstructHourBuckets(start, end)
		.map(date => ({ e1: 0, e2: 0, t: 0, date, datestring: date.format("MMM D, h:mm A") }));
	for (var h = 0, d = 0; d < estrogen.length && h < hours.length; h++) {
		// -1 to make it "is after or equal to"
		while (d < estrogen.length && hours[h].date.isAfter(moment.unix(estrogen[d].timestamp - 1))) {
			ApplyHourlyDose(h, hours, estrogen[d]);
			d++;
		}
	}
	return hours;
};

export const SimulateQuarterHourly = (estrogen, antiandrogen) => {
	const { start } = getStartAndEndTime(estrogen, antiandrogen);
	if (!start) { return []; }
	start.set({ minute: 0, second: 0 });
	const end = moment().set({ minute: 0, second: 0 }).add(3, "hour");
	const quarterHours = ConstructQuarterHourBuckets(start, end)
		.map(date => ({ e1: 0, e2: 0, t: 0, date, datestring: date.format("MMM D, h:mm A") }));
	for (var h = 0, d = 0; d < estrogen.length && h < quarterHours.length; h++) {
		// -1 to make it "is after or equal to"
		while (d < estrogen.length && quarterHours[h].date.isAfter(moment.unix(estrogen[d].timestamp - 1))) {
			ApplyQuarterHourlyDose(h, quarterHours, estrogen[d]);
			d++;
		}
	}
	return quarterHours;
};
