import "./Post.css";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import PostEstrogen from "./PostEstrogen";
import PostAntiandrogen from "./PostAntiandrogen";
import PostNote from "./PostNote";

const Post = () => {
	const [modalMessage, setModalMessage] = useState(null);

	return (<>
		<Container>
		<Row className="justify-content-center">
		<Col sm={12} md={10} lg={8} xl={6} >
		<Container>
			<PostEstrogen onError={(e) => setModalMessage(e)}/>
			<PostAntiandrogen onError={(e) => setModalMessage(e)}/>
			<PostNote onError={(e) => setModalMessage(e)}/>
		</Container>
		</Col>
		</Row>
		</Container>

		<Modal
			show={modalMessage !== null}
			onHide={() => setModalMessage(null)}
			backdrop="static"
			keyboard={false}
		>
			<Modal.Header>
				<Modal.Title>Database</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{modalMessage}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="primary" onClick={() => setModalMessage(null)}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	</>);
}

export default Post;
