import "./Login.css";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { auth } from "./firebaseInit";

const Login = ({ tryLogin }) => {
	const history = useHistory();
	const [password, setPassword] = useState("");

	const handleSubmit = (e) => {
		e.preventDefault();
		auth.signInWithEmailAndPassword("robbykraft@gmail.com", password)
			.then(user => {
				if (user) { history.push("/dose"); }
			});
	}

	return (
		<Container className="page-container">
			<Row className="page-row">
				<Col md={8} lg={4} className="page-column m-auto">
				<Form onSubmit={handleSubmit}>
					<Form.Group className="mb-3" controlId="formBasicPassword">
						<Form.Label>Password</Form.Label>
						<Form.Control
							value={password}
							onChange={e => setPassword(e.target.value)}
							type="password"
							placeholder="Password" />
					</Form.Group>
					<Button variant="primary" type="submit">
						Login
					</Button>
				</Form>
				</Col>
			</Row>
		</Container>
	);
};

export default Login;
