import { useState } from "react";
import moment from "moment";
import {
	ResponsiveContainer,
	LineChart,
	Line,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip
} from "recharts";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { pink, blue, purple, lightpurple } from "../colors";

const ChartDaily = ({ levels, dailyDoses }) => {

	const [day, setDay] = useState(moment());
	const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
	const isDesktop = window.innerWidth > 600;

	const getMaxY = () => {
		if (!levels) { return 1; }
		let max = 0;
		levels.forEach(el => {
			if (el.e1 > max) { max = el.e1; }
			if (el.e2 > max) { max = el.e2; }
			if (el.t > max) { max = el.t; }
		});
		return max;
	};

	const filteredDay = () => {
		if (!levels) { return []; }
		levels.forEach(el => el.timestring = el.date.format("h:mm A"));
		const a = day.clone()
			.set({ hour: 23, minute: 59, second: 0 })
			.subtract(1, "day");
		const b = a.clone().add(1, "day").add(1, "minute").add(3, "hours");
		return levels
			.filter(el => el.date.isBetween(a, b, undefined, "[]"));
	};

	const changeDay = (change) => setDay(day.clone().add(change, "day"));

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className="custom-tooltip">
					<h5 className="label">{label}</h5>
					<ul>
						{payload.map((el, i) => (<li
							key={i}
							style={{color: el.color}}
							>{`${el.name}: ${el.value.toFixed(0)} mg`}</li>))}
					</ul>
				</div>
			);
		}
		return null;
	};

	const makeLineChart = (data, className) => {
		return (
			<ResponsiveContainer width="100%" height={300}>
				<LineChart
				className={className}
				background={{ fill: "black" }}
				height={300}
				data={data}
			>
					<Line
						isAnimationActive={false}
						dot={false}
						type="monotone"
						dataKey="e1"
						strokeWidth={3}
						stroke={darkMode ? lightpurple : purple} />
					<Line
						isAnimationActive={false}
						dot={false}
						type="monotone"
						dataKey="e2"
						strokeWidth={3}
						stroke={pink} />
					<Line
						isAnimationActive={false}
						dot={false}
						type="monotone"
						dataKey="t"
						strokeWidth={3}
						stroke={blue} />
					<CartesianGrid stroke={darkMode ? "#fff2" : "#0002"} strokeDasharray="5 5" />
					<XAxis
						stroke={darkMode ? "#aaa" : "black"}
						minTickGap={30}
						interval="preserveStartEnd"
						dataKey="timestring" />
					<YAxis
						stroke={darkMode ? "#aaa" : "black"}
						domain={[0, Math.ceil(getMaxY())]}/>
					<Tooltip content={<CustomTooltip/>} />
				</LineChart>
			</ResponsiveContainer>
		);
	};

	const makeDailySummary = (selectedDay, dailyDoses) => {
		if (!selectedDay || ! dailyDoses) { return ""; }
		const todays = dailyDoses
			.filter(el => el.date.isSame(selectedDay, "day"))
			.shift();
		if (!todays) { return ""; }
		const data = todays.doses.sort((a, b) => a.timestamp - b.timestamp);
		// console.log("Sum", data);
		const sumEstradiol = data
			.filter(el => el.drug === "estradiol")
			.map(el => el.form === "valerate" ? el.mg * 0.75 : el.mg)
			.reduce((a, b) => a + b, 0);
		const sumBica = data
			.filter(el => el.drug === "bicalutamide")
			.map(el => el.mg)
			.reduce((a, b) => a + b, 0);
			// <p style={{fontWeight: 600, textAlign: "center"}}>{sumEstradiol} mg estradiol, {sumBica} mg bicalutamide</p>
		return (<>
			<div className="container-xs">
			<p style={{fontWeight: 600, textAlign: "center"}}>{sumEstradiol} mg <span style={{color: pink}}>estradiol</span>, {sumBica} mg <span style={{color: blue}}>bicalutamide</span></p>
			<Table sm={8} striped bordered hover>
				<tbody>
				{data.map((el, i) => (
					<tr key={"tr"+i}>
						<td key={"date"+i}>{el.date.format("h:mm A")}</td>
						<td key={"mg"+i}>{el.mg}mg</td>
						<td key={"drug"+i}>{el.drug}{el.form?"-"+el.form:""}</td>
						<td key={"route"+i}>{el.route}</td>
					</tr>))}
				</tbody>
			</Table>
			</div>
		</>);
		// return (
		// 	<ul style={{margin: "auto", listStyle: "none"}}>
		// 		{todays.doses
		// 			.sort((a, b) => a.timestamp - b.timestamp)
		// 			.map(el => `${el.date.format("h:mm A")}: ${el.mg}mg ${el.drug}${el.form?"-"+el.form:""} ${el.route}`)
		// 			.map((str, i) => <li key={`list-${i}`}>{str}</li>)
		// 			}
		// 	</ul>);
	};

	return (<>
		<h1>{day.isSame(moment(), "day") ? "Today" : "Daily"} Simulator</h1>
		{makeLineChart(filteredDay(), "chart-daily")}
		<div className="date-select">
			<Button variant="primary" onClick={() => changeDay(-1)}>Back</Button>
			<span className="big-date">{isDesktop 
				? day.format("MMM D, YYYY")
				: day.format("MMM D")}</span>
			<Button variant="primary" onClick={() => changeDay(1)}>Next</Button>
		</div>
		<div className="flex-center" style={{margin: "1rem 0"}}>
			{makeDailySummary(day, dailyDoses)}
		</div>
	</>);
}

export default ChartDaily;
