import "./Charts.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ChartAllTime from "./ChartAllTime";
import ChartDaily from "./ChartDaily";
import ChartDoses from "./ChartDoses";
import ReferenceChart from "./ReferenceChart";
import {
	estrogenSwallowHourly,
	estrogenSublingualHourly,
	estrogenSwallowQuarterHourly,
	estrogenSublingualQuarterHourly,
} from "../Data/DoseLevels";

const Charts = ({ doses, daily, hourly, quarterHourly }) => {

	return (
		<Container>
			<Row>
				<Col md={10} lg={8}>
					<ChartDaily levels={quarterHourly.levels} dailyDoses={daily.doses} />
				</Col>
			</Row>
			<Row>
				<Col md={10} lg={8}>
					<h1>All Time Simulator</h1>
					<ChartAllTime levels={hourly.levels} />
				</Col>
			</Row>
			<Row>
				<Col md={10} lg={8}>
					<h1>Daily Doses</h1>
					<ChartDoses doses={doses} dailyDoses={daily.doses} />
				</Col>
			</Row>
			<Row>
				<Col md={10} lg={8}>
					<h1>Notes</h1>
					<p>Daily charts measures a "day" from 4am to 4am. Midnight-4am doses are delegated to the previous day.</p>
					<p>Estradiol-Valerate contains 1.5 estradiol per 2 mg of pill, hence, it counts x0.75 times.</p>
					<p>The simulator applies each dose one at a time by extracting from these reference data at each time offset and by scaling the dose based on what was taken. These reference data model a 1 mg dose of estradiol.</p>
				</Col>
			</Row>
			<Row>
				<Col md={10} lg={8}>
					<h1>Reference Charts</h1>
				</Col>
			</Row>
			<Row>
				<Col className="col-reference-chart">
					<h3>Swallow</h3>
					<h5>hourly</h5>
					<ReferenceChart
						width={300}
						height={200}
						data={estrogenSwallowHourly().slice(0, 24)}
						yDomain={[0, 600]}
					/>
					<h5>quarter hourly</h5>
					<ReferenceChart
						width={300}
						height={200}
						data={estrogenSwallowQuarterHourly().slice(0, 96)}
						yDomain={[0, 600]}
					/>
				</Col>
				<Col className="col-reference-chart">
					<h3>Sublingual/Buccal</h3>
					<h5>hourly</h5>
					<ReferenceChart
						width={300}
						height={200}
						data={estrogenSublingualHourly().slice(0, 24)}
						yDomain={[0, 600]}
					/>
					<h5>quarter hourly</h5>
					<ReferenceChart
						width={300}
						height={200}
						data={estrogenSublingualQuarterHourly().slice(0, 96)}
						yDomain={[0, 600]}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={10} lg={8}>
					<p>The reference data was constructed by modeling a curve to match as close as possible the charts from <a href="https://en.wikipedia.org/wiki/Pharmacokinetics_of_estradiol#Absorption">Wikipedia: Pharmacokinetics of Estradiol</a>.</p>
				</Col>
			</Row>
		</Container>
	);
};

export default Charts;
