import React from "react";
import moment from "moment";
import Card from "react-bootstrap/Card";
import "./Note.css";
import "./VideoNote.css";

const VideoNote = ({ post }) => {
	return (
		<Card>
			<Card.Header as="h5">
				{moment.unix(post.timestamp).format("MMMM D, YYYY")}
			</Card.Header>
			<Card.Body className="video-container">
				<iframe
					className="responsive-iframe"
					width="100%"
					src={`https://www.youtube.com/embed/${post.youtube}`}
					title="vlog"
					frameBorder="0"
					allowFullScreen>
				</iframe>
			</Card.Body>
		</Card>
	);
};

export default VideoNote;
