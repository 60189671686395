import React from "react";
import "./Toggle.css";

const Toggle = (props) => {

  const onClick = (e) => {
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <label className="switch">
      <input type="checkbox" onClick={onClick}/>
      <span className="slider round"></span>
    </label> 
  );
}

export default Toggle;
