//import firebase from "firebase";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import firebaseConfig from './firebaseConfig';

const firebaseApp = firebase.initializeApp(firebaseConfig);
const auth = firebaseApp.auth();
const database = firebase.database();

export {
	firebaseApp,
	auth,
	database,
};

// export default !firebase.apps.length
// 	? firebase.initializeApp(firebaseConfig)
// 	: firebase.app();
