import React from "react";
import moment from "moment";
import Card from "react-bootstrap/Card";
import "./Note.css";

const TextNote = ({ post }) => {
	return (
		<Card>
			<Card.Header as="h5">
				{moment.unix(post.timestamp).format("MMMM D, YYYY")}
			</Card.Header>
			<Card.Body>
				{post.body.split("\n")
					.filter(s => s !== "")
					.map((s, i) => <Card.Text key={i}>{s}</Card.Text>)}
			</Card.Body>
		</Card>
	);
};

export default TextNote;
