import {
	LineChart,
	Line,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip
} from "recharts";
import { pink, purple, lightpurple } from "../colors";

const ReferenceChart = ({ width, height, data, yDomain }) => {
	const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className="custom-tooltip">
					<ul>
						{payload.map((el, i) => (<li
							key={i}
							style={{color: el.color}}
							>{`${el.name}: ${el.value.toFixed(2)} mg`}</li>))}
					</ul>
				</div>
			);
		}
		return null;
	};

	return (
		<LineChart
			width={300}
			height={200}
			data={data}
		>
			<Line
				isAnimationActive={false}
				dot={false}
				type="monotone"
				dataKey="e1"
				strokeWidth={2}
				stroke={darkMode ? lightpurple : purple} />
			<Line
				isAnimationActive={false}
				dot={false}
				type="monotone"
				dataKey="e2"
				strokeWidth={2}
				stroke={pink} />
			<CartesianGrid stroke={darkMode ? "#fff2" : "#0002"} strokeDasharray="5 5" />
			<XAxis interval="preserveStartEnd" dataKey="datestring" />
			<YAxis
				stroke={darkMode ? "#aaa" : "black"}
				domain={[0, 600]}/>
			<Tooltip content={<CustomTooltip/>} />
		</LineChart>
	);
};

export default ReferenceChart;
