import { useState } from "react";
import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { database } from "../firebaseInit";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const PostAntiandrogen = ({ onError }) => {
	const [mg, setMg] = useState("37.5");
	const drug = "bicalutamide";
	const route = "swallow";
	const history = useHistory();

	const onFormSubmit = (e) => {
		e.preventDefault();
		const mgNumber = parseFloat(mg);
		if (mgNumber == null || isNaN(mgNumber) || mgNumber < 0.0) {
			return;
		}
		let newPostKey = database.ref().child("doses").child("antiandrogen").push().key;
		let updates = {};
		updates["/doses/antiandrogen/" + newPostKey] = {
			mg: mgNumber,
			drug,
			route,
			timestamp: moment().unix()
		};
		database.ref().update(updates).then((error) => {
			if (error) { return onError(error); }
			history.push("/hormones");
		});
	};

	return (
		<Form onSubmit={onFormSubmit}>
			<Form.Group>
				<h1>New Antiandrogen Dose</h1>
				<Row className="align-items-center">
					<Col xs={8}>
						<Slider
							min={0}
							max={100}
							step={5}
							value={mg}
							onChange={setMg}
						/>
					</Col>
					<Col xs={4}>
						<Form.Control
							size="lg"
							type="text"
							value={mg}
							onChange={(e) => setMg(e.target.value)} />
					</Col>
				</Row>
				<Row className="form-row">
					<Col>
						<Form.Check
							label="Bicalutamide"
							name="antiandrogen"
							type="radio"
							defaultChecked={true}
							id="antiandrogen-radio-1"
						/>
					</Col>
				</Row>
				<Row className="form-row justify-content-end">
					<Col xs="auto" className="justify-content-end">
						<Button variant="primary" type="submit">Submit</Button>
					</Col>
				</Row>
			</Form.Group>
		</Form>
	);
};

export default PostAntiandrogen;
