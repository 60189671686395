import React from "react";
import { Route, Redirect } from "react-router-dom";
import { auth } from "./firebaseInit";

export default function PrivateRoute({ component: Component, ...rest }) {
	return (
		<Route
			{...rest}
			render={props => {
				return auth.currentUser
					? <Component {...props} />
					: <Redirect to="/login" />
			}}
		></Route>
	);
}
