import "./Sizes.css";
import React from "react";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Toggle from "./Components/Toggle";

const capitalize = word => word
  .toLowerCase()
  .replace(/\w/, firstLetter => firstLetter.toUpperCase());

const conversion = {
	"cm": {
		"cm": 1,
		"in": 0.393700787401575
	},
	"mm": {
		"mm": 1,
		"in": 0.0393700787401575
	},
	"kg": {
		"kg": 1,
		"lbs": 2.20462
	}
};

const Sizes = (props) => {
	let [metric, setMetric] = React.useState(true);

	const units = (oldUnit) => {
		switch (oldUnit) {
			case "cm": return metric ? "cm" : "in";
			case "mm": return metric ? "mm" : "in";
			case "kg": return metric ? "kg" : "lbs";
			default: return oldUnit;
		}
	};

	const getWeekKeys = (body) => {
		const weektable = {};
		Object.keys(body)
			.forEach(key => Object.keys(body[key])
				.forEach(week => { weektable[week] = true; }));
		return Object.keys(weektable)
			.map(string => parseInt(string))
			.filter(n => !isNaN(n))
			.sort((a, b) => a - b);
	}

	const meterize = (input, oldUnits) => {
		if (typeof input === "number") {
			const res = input * conversion[oldUnits][units(oldUnits)];
			return res.toString().length > 5
				? res.toFixed(1)
				: res;
		} else {
			return input;
		}
	};

	const monthString = n => {
		switch (n) {
			case 0: return "Pre-HRT";
			case 1: return `${n} Month`;
			default: return `${n} Months`;
		}
	}

	const bodySizesTable = (body) => {
		const weeks = getWeekKeys(body)
			.filter(n => n % 4 === 0);

		return (
			<Table striped bordered hover>
				<thead>
					<tr>
						<th></th>
						{weeks
							.map(w => parseInt(w / 4))
							.map((m, i) => (<th key={i}>{monthString(m)}</th>))
						}
					</tr>
				</thead>
				<tbody>
					{Object.keys(body).map((key, i) => (
						<tr key={i}>
							<td>{capitalize(key)}</td>
							{weeks.map((w, i) => (<td key={i}>
								{meterize(body[key][w], body[key].units)} {body[key][w] != null ? units(body[key].units) : ""}
							</td>))}
						</tr>
					))}
				</tbody>
			</Table>
		);
	};

	const WeeklyTable = (body) => {
		const weeks = getWeekKeys(body);
		const keys = Object.keys(body)
			.filter(key => body[key][5] !== undefined
				&& body[key][6] !== undefined);
		return (<Table striped bordered hover>
			<thead>
				<tr>
					<th>Week</th>
					{weeks.map((n, i) => <th key={i}>#{n}</th>)}
				</tr>
			</thead>
			<tbody>
				{keys.map((key, i) => (
					<tr key={i}>
						<td>{capitalize(key)}</td>
						{weeks.map((w, i) => <td key={i}>{meterize(body[key][w], body[key].units)}</td>)}
					</tr>
				))}
			</tbody>
		</Table>);
	};

	const clothingSizesTable = (clothes) => {
		const columns = Object
			.keys(clothes[Object.keys(clothes)[0]])
			.sort((a, b) => b.length - a.length);
		return (<Table striped bordered hover>
			<thead>
				<tr>
					<th></th>
					{columns.map(col => (<th>{col}</th>))}
				</tr>
			</thead>
			<tbody>
				{Object.keys(clothes).map((key, i) => (
					<tr key={i}>
						<td>{capitalize(key)}</td>
						{columns.map((col, i) => (
							<td key={i}>{clothes[key][col]}</td>
						))}
					</tr>
				))}
			</tbody>
		</Table>);
	};

	return (!props.data || !props.data.clothes)
		? (<></>)
		: (<Container>
			<Row>
				<Col className="unit-control">
					<span>metric</span>
					<Toggle onClick={(e) => setMetric(!e.target.checked)}/>
					<span>inches</span>
				</Col>
			</Row>
			<Row>
				<Col sm={12} md={10} lg={8} xl={6}>
					<h1>Body Measurements</h1>
					<div className="table-responsive">
						{bodySizesTable(props.data.body)}
					</div>
				</Col>
			</Row>

			<Row>
				<Col sm={12} md={10} lg={8} xl={6}>
					<h1>Weekly (units: {units("cm")})</h1>
					{WeeklyTable(props.data.body)}
				</Col>
			</Row>

			<Row>
				<Col sm={8} md={6} lg={4}>
					<h1>Clothing Sizes</h1>
					{clothingSizesTable(props.data.clothes)}
				</Col>
			</Row>
		</Container>
	);
}

export default Sizes;
