const firebaseConfig = {
	apiKey: "AIzaSyDs4Wd5oi3h-oo2WkQ8sWci8Feq0vlEido",
	authDomain: "gendertransition-fde29.firebaseapp.com",
	databaseURL: "https://gendertransition-fde29-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "gendertransition-fde29",
	storageBucket: "gendertransition-fde29.appspot.com",
	messagingSenderId: "684868006623",
	appId: "1:684868006623:web:f7b463b32a900b3032ef6a"
};

export default firebaseConfig;
