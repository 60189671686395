import { useState } from "react";
import { useHistory } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { database } from "../firebaseInit";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const PostEstrogen = ({ onError }) => {
	const [mg, setMg] = useState("1");
	const drug = "estradiol";
	const [form, setForm] = useState(null);
	const [route, setRoute] = useState(null);
	const history = useHistory();

	const onFormSubmit = (e) => {
		e.preventDefault();
		const mgNumber = parseFloat(mg);
		if (form == null || route == null ||
			mgNumber == null || isNaN(mgNumber) || mgNumber < 0.0) {
			return;
		}
		let newPostKey = database.ref().child("doses").child("estrogen").push().key;
		let updates = {};
		updates["/doses/estrogen/" + newPostKey] = {
			mg: mgNumber,
			drug,
			form,
			route,
			timestamp: moment().unix()
		};
		database.ref().update(updates).then((error) => {
			if (error) { return onError(error); }
			history.push("/hormones");
		});
	};

	return (
		<Form onSubmit={onFormSubmit}>
			<Form.Group>
				<h1>New Estradiol Dose</h1>
				<Row className="align-items-center">
					<Col xs={8}>
						<Slider
							min={0}
							max={3}
							step={0.1}
							value={mg}
							onChange={setMg}
						/>
					</Col>
					<Col xs={4}>
						<Form.Control
							size="lg"
							type="text"
							value={mg}
							onChange={(e) => setMg(e.target.value)} />
					</Col>
				</Row>
				<Row className="form-row">
					<Col>
						<Form.Check
							label="Estradiol Enanthate"
							name="estrogen-radio"
							type="radio"
							id="estrogen-radio-0"
							onChange={() => setForm("enanthate")}
						/>
						<Form.Check
							label="Estradiol Cypionate"
							name="estrogen-radio"
							type="radio"
							id="estrogen-radio-1"
							onChange={() => setForm("cypionate")}
						/>
						<Form.Check
							label="Estradiol Hemihydrate"
							name="estrogen-radio"
							type="radio"
							id="estrogen-radio-2"
							onChange={() => setForm("hemihydrate")}
						/>
						<Form.Check
							label="Estradiol Valerate"
							name="estrogen-radio"
							type="radio"
							id="estrogen-radio-3"
							onChange={() => setForm("valerate")}
						/>
					</Col>
					<Col>
						<Form.Check
							label="injection"
							name="estrogen-route-radio"
							type="radio"
							id="estrogen-route-radio-0"
							onChange={() => setRoute("injection")}
						/>
						<Form.Check
							label="swallow"
							name="estrogen-route-radio"
							type="radio"
							id="estrogen-route-radio-1"
							onChange={() => setRoute("swallow")}
						/>
						<Form.Check
							label="sublingual"
							name="estrogen-route-radio"
							type="radio"
							id="estrogen-route-radio-2"
							onChange={() => setRoute("sublingual")}
						/>
						<Form.Check
							label="buccal"
							name="estrogen-route-radio"
							type="radio"
							id="estrogen-route-radio-3"
							onChange={() => setRoute("buccal")}
						/>
						<Form.Check
							label="gel"
							name="estrogen-route-radio"
							type="radio"
							id="estrogen-route-radio-4"
							onChange={() => setRoute("gel")}
						/>
					</Col>
				</Row>
				<Row className="form-row justify-content-end">
					<Col xs="auto" className="justify-content-end">
						<Button variant="primary" type="submit">Submit</Button>
					</Col>
				</Row>
			</Form.Group>
		</Form>
	);
};

export default PostEstrogen;
