import moment from "moment";
import {
	getStartAndEndTime,
	ConstructDayBuckets,
} from "./Time";

export const makeDailyDoses = (doses) => {
	// bucket doses into daily buckets
	const { start } = getStartAndEndTime(doses.estrogen, doses.antiandrogen);
	if (!start) { return []; }
	start.set({ hour: 0, minute: 0, second: 0 });
	const end = moment().set({ hour: 0, minute: 0, second: 0 });
	const dailyDoses = ConstructDayBuckets(start, end)
		.map(date => ({ date, doses: [] }));
	// n squared. todo fix
	doses.estrogen.forEach((el) => {
		for (let i = 0; i < dailyDoses.length; i++) {
			if (el.date.clone().subtract(4, "hour").isSame(dailyDoses[i].date, "day")) {
				dailyDoses[i].doses.push(el);
				break;
			}
		}
	});
	doses.antiandrogen.forEach((el) => {
		for (let i = 0; i < dailyDoses.length; i++) {
			if (el.date.clone().subtract(4, "hour").isSame(dailyDoses[i].date, "day")) {
				dailyDoses[i].doses.push(el);
				break;
			}
		}
	});
	dailyDoses.forEach((el) => {
		el.estradiol = el.doses
			.filter(el => el.drug === "estradiol")
			.map(el => el.form === "valerate" ? el.mg * 0.75 : el.mg)
			.reduce((a, b) => a + b, 0);
		el.bicalutamide = el.doses
			.filter(el => el.drug === "bicalutamide")
			.map(el => el.mg)
			.reduce((a, b) => a + b, 0);
		el.datestring = el.date.format("MMM D, YYYY");
	});
	return dailyDoses;
};
