import './App.css';
import moment from "moment";
import React from "react";
import {
	BrowserRouter as Router,
	Redirect,
	Switch,
	Route,
} from "react-router-dom";
import { database } from "./firebaseInit";
import PrivateRoute from "./PrivateRoute";
import Header from "./Header/Header";
import Charts from "./Charts/Charts";
import Sizes from "./Sizes";
import Notes from "./Notes/Notes";
import Login from "./Login";
import Dose from "./Dose/Post";
// data
import { makeDailyDoses } from "./Data/Data";
import {
	SimulateQuarterHourly,
	SimulateHourly
} from "./Data/SimulateLevels";

class App extends React.Component {
	// each individual entry from the database which is on its own being
	// watched, and when updated will retrigger an update
	state = {
		// database data
		doses: { estrogen: [], antiandrogen: [] },
		posts: [],
		sizes: {},
		// sorted data
		daily: {},
		hourly: {},
		quarterHourly: {},
	};

	componentDidMount() {
		this.downloadData();
	}

	downloadData() {
		database.ref("/doses").on("value", (snapshot) => this.updateDoses(snapshot.val()));
		database.ref("/posts").on("value", (snapshot) => this.setState({
			posts: Object.values(snapshot.val())
		}));
		database.ref("/sizes").on("value", (snapshot) => this.setState({
			sizes: snapshot.val()
		}));
	}

	updateDoses(snapshot) {
		const sortfunc = (a, b) => a.timestamp - b.timestamp;
		// each object has a "timestamp" key, append 2 more keys:
		// "date", a moment object, "datestring" a human readable string
		const appendReadableDate = (arr = []) => arr.forEach(el => {
			el.date = moment.unix(el.timestamp);
			el.datestring = el.date.format("MMM D, h:mm A");
		});
		// build new data using the incoming data from the database
		const doses = {
			estrogen: Object.values(snapshot.estrogen).sort(sortfunc),
			antiandrogen: Object.values(snapshot.antiandrogen).sort(sortfunc),
		};
		appendReadableDate(doses.estrogen);
		appendReadableDate(doses.antiandrogen);
		const hourlyLevels = SimulateHourly(doses.estrogen, doses.antiandrogen);
		const quarterlyLevels = SimulateQuarterHourly(doses.estrogen, doses.antiandrogen);
		const dailyDoses = makeDailyDoses(doses);
		this.setState({
			doses,
			daily: { doses: dailyDoses },
			hourly: { levels: hourlyLevels},
			quarterHourly: { levels: quarterlyLevels },
		});
	}

	render () {
		return (
			<Router basename="/trans">
				<Header />
				<Switch>
					<Route exact path="/">
						<Redirect to="/hormones" />
					</Route>
					<Route path="/hormones">
						<Charts
							doses={this.state.doses}
							daily={this.state.daily}
							hourly={this.state.hourly}
							quarterHourly={this.state.quarterHourly}
						/>
					</Route>
					<Route path="/sizes">
						<Sizes data={this.state.sizes}/>
					</Route>
					<Route path="/notes">
						<Notes posts={this.state.posts}/>
					</Route>
					<PrivateRoute path="/dose" component={Dose}>
					</PrivateRoute>
					<Route path="/login">
						<Login />
					</Route>
				</Switch>
				<div className="footer"></div>
			</Router>
		);
	};
}

export default App;
