import moment from "moment";

export const getStartAndEndTime = (...arrays) => {
	const timestamps = [].concat(...arrays)
		.map(el => el.timestamp)
		.sort((a, b) => a - b);
	return (timestamps.length < 2)
		? { start: undefined, end: undefined }
		: {
			start: moment.unix(timestamps.shift()),
			end: moment.unix(timestamps.pop())
		};
};

export const ConstructDayBuckets = (start, end) => {
	const duration = moment.duration(end.diff(start));
	const count = Math.ceil(duration.as("days"));
	return Array.from(Array(count))
		.map((_, i) => start.clone().add(i, "day"))
};

export const ConstructHourBuckets = (start, end) => {
	const duration = moment.duration(end.diff(start));
	const count = Math.ceil(duration.as("hours"));
	return Array.from(Array(count))
		.map((_, i) => start.clone().add(i, "hour"))
};

export const ConstructQuarterHourBuckets = (start, end) => {
	const duration = moment.duration(end.diff(start));
	const count = Math.ceil(duration.as("hours")) * 4;
	return Array.from(Array(count))
		.map((_, i) => start.clone().add(i * 15, "minutes"))
};
