import { useState } from "react";
import {
	ResponsiveContainer,
	LineChart,
	Line,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip
} from "recharts";
import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import { pink, blue, purple, lightpurple } from "../colors";

const ChartAllTime = ({ levels }) => {
	const [hormonesXDomain, setDomain] = useState([0, 1]);
	const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

	const getMaxY = () => {
		if (!levels) { return 1; }
		let max = 0;
		levels.forEach(el => {
			if (el.e1 > max) { max = el.e1; }
			if (el.e2 > max) { max = el.e2; }
			if (el.t > max) { max = el.t; }
		});
		return max;
	};

	const filteredData = () => {
		if (!levels) { return []; }
		return levels
			.slice(...hormonesXDomain.map(n => n * levels.length));
	}

	const CustomTooltip = ({ active, payload, label }) => {
		if (active && payload && payload.length) {
			return (
				<div className="custom-tooltip">
					<h5 className="label">{label}</h5>
					<ul>
						{payload.map((el, i) => (<li
							key={i}
							style={{color: el.color}}
							>{`${el.name}: ${el.value.toFixed(0)} mg`}</li>))}
					</ul>
				</div>
			);
		}
		return null;
	};

	const makeLineChart = (data, className) => {
		return (
			<ResponsiveContainer width="100%" height={300}>
				<LineChart
				className={className}
				height={300}
				data={data}
			>
					<Line
						isAnimationActive={false}
						dot={false}
						type="monotone"
						dataKey="e1"
						strokeWidth={1.5}
						stroke={darkMode ? lightpurple : purple} />
					<Line
						isAnimationActive={false}
						dot={false}
						type="monotone"
						dataKey="e2"
						strokeWidth={1.5}
						stroke={pink} />
					<Line
						isAnimationActive={false}
						dot={false}
						type="monotone"
						dataKey="t"
						strokeWidth={1.5}
						stroke={blue} />
					<CartesianGrid stroke={darkMode ? "#fff2" : "#0002"} strokeDasharray="5 5" />
					<XAxis
						stroke={darkMode ? "#aaa" : "black"}
						minTickGap={150}
						interval="preserveStartEnd"
						dataKey="datestring" />
					<YAxis
						stroke={darkMode ? "#aaa" : "black"}
						domain={[0, Math.ceil(getMaxY())]}/>
					<Tooltip content={<CustomTooltip/>} />
				</LineChart>
			</ResponsiveContainer>
		);
	}

	const changeHormonesScale = (e) => setDomain(e);

	return (<>
		{makeLineChart(filteredData(), "chart-all-time")}
		<div className="date-select">
			{/* <Slider /> */}
			<Range
				onChange={(...args) => changeHormonesScale(...args)}
				min={0}
				max={1}
				step={0.001}
				defaultValue={[0, 1]}
				style={{width: "400px"}}/>
		</div>
	</>);
}

export default ChartAllTime;
